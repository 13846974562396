/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const simpleContactSearch = /* GraphQL */ `
  query SimpleContactSearch(
    $tenant: ID!
    $searchQuery: String
    $sort: [SimpleContactSearchSortInput]
    $fields: [String]
    $limit: Int
    $nextToken: [String]
  ) {
    simpleContactSearch(
      tenant: $tenant
      searchQuery: $searchQuery
      sort: $sort
      fields: $fields
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const expandedContactSearch = /* GraphQL */ `
  query ExpandedContactSearch(
    $filter: AWSJSON
    $sort: [ExpandedContactSearchSortInput]
    $limit: Int
    $nextToken: String
  ) {
    expandedContactSearch(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const esQuery = /* GraphQL */ `
  query EsQuery(
    $model: EsQueryModel!
    $action: EsQueryAction!
    $query: AWSJSON!
  ) {
    esQuery(model: $model, action: $action, query: $query)
  }
`;
export const getSegment = /* GraphQL */ `
  query GetSegment($id: ID!) {
    getSegment(id: $id) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const listSegments = /* GraphQL */ `
  query ListSegments(
    $filter: ModelSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivityLog = /* GraphQL */ `
  query GetActivityLog($id: ID!) {
    getActivityLog(id: $id) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listActivityLogs = /* GraphQL */ `
  query ListActivityLogs(
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          fieldsMappingId
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          status
          expireDate
          stopOnCompletion
          resetOnCompletion
          static
          campaignSchedule
          segmentSchedule
          updatesEmail
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        c2aId
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          timeZone
          dnc
          updatedBy
          customFields
          expireDt
          complianceRequired
          preview
          outboundCallerId
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTenantSettings = /* GraphQL */ `
  query GetTenantSettings($id: ID!) {
    getTenantSettings(id: $id) {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTenantSettingss = /* GraphQL */ `
  query ListTenantSettingss(
    $filter: ModelTenantSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        icApp
        icVendor
        icBU
        icCluster
        icUserName
        icPassword
        icGetAuthTokenURL
        icAccessKey
        icAppSecret
        icDiscoveryDomain
        icScriptName
        icAPIOverride
        icSMSMapping {
          enabled
        }
        icSMSProviderCredentials {
          provider
          data
          verified
        }
        ftpImport
        ftpExport
        dashBoardCharts
        e2DashCharts
        ppDashCharts
        e2ClientId
        e2ClientSecret
        e2DynamTenantId
        onPremAccessUrl
        onPremVersion
        onPremAuthUrl
        apiKey
        licenses
        customFields {
          name
          displayName
          type
        }
        dynamicsConnection
        dynamicsDispoFilter
        email
        umaxConfig
        permanentCampaigns
        minimumSeats {
          productId
          minimumSeats
        }
        productSku {
          product
          productId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFtpImport = /* GraphQL */ `
  query GetFtpImport($id: ID!) {
    getFtpImport(id: $id) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      createdAt
      updatedAt
    }
  }
`;
export const listFtpImports = /* GraphQL */ `
  query ListFtpImports(
    $filter: ModelFtpImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFtpImports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        host
        username
        password
        path
        privateKey
        port
        fieldMappingId
        lastCheck
        fileNameToSource
        algorithms {
          cipher
        }
        readyTimeout
        reportEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      resetOnCompletion
      static
      campaignSchedule
      segmentSchedule
      updatesEmail
      createdAt
      updatedAt
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDoNotCall = /* GraphQL */ `
  query GetDoNotCall($tenant: ID!, $phone: AWSPhone!, $sources: String!) {
    getDoNotCall(tenant: $tenant, phone: $phone, sources: $sources) {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const listDoNotCalls = /* GraphQL */ `
  query ListDoNotCalls(
    $tenant: ID
    $phoneSources: ModelDoNotCallPrimaryCompositeKeyConditionInput
    $filter: ModelDoNotCallFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDoNotCalls(
      tenant: $tenant
      phoneSources: $phoneSources
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenant
        phone
        sources
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaignLog = /* GraphQL */ `
  query GetCampaignLog($id: ID!) {
    getCampaignLog(id: $id) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const listCampaignLogs = /* GraphQL */ `
  query ListCampaignLogs(
    $filter: ModelCampaignLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        campaign
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfigProfile = /* GraphQL */ `
  query GetConfigProfile($id: ID!) {
    getConfigProfile(id: $id) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const listConfigProfiles = /* GraphQL */ `
  query ListConfigProfiles(
    $filter: ModelConfigProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        direction
        mediaType
        crmEntity
        org {
          name
          id
          url
          version
        }
        connection {
          dynamicsTenant
          appSecret
          clientId
          altUrl
          authUrl
          onPremVersion
        }
        skillIds
        multiSearch
        openClosed
        searchType
        search
        searchAction
        activityEntityMap
        logging
        postLogging
        closeActivity
        loggingAction
        transferSkills
        uiInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPreProcess = /* GraphQL */ `
  query GetPreProcess($id: ID!) {
    getPreProcess(id: $id) {
      id
      tenant
      name
      action
      createdAt
      updatedAt
    }
  }
`;
export const listPreProcesss = /* GraphQL */ `
  query ListPreProcesss(
    $filter: ModelPreProcessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreProcesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCifLog = /* GraphQL */ `
  query GetCifLog($id: ID!) {
    getCIFLog(id: $id) {
      id
      agentId
      tenant
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const listCifLogs = /* GraphQL */ `
  query ListCifLogs(
    $filter: ModelCIFLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCIFLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        agentId
        tenant
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUMaxLog = /* GraphQL */ `
  query GetUMaxLog($id: ID!) {
    getUMaxLog(id: $id) {
      id
      agentId
      tenant
      icBu
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const listUMaxLogs = /* GraphQL */ `
  query ListUMaxLogs(
    $filter: ModeluMaxLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUMaxLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        agentId
        tenant
        icBu
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFieldsMapping = /* GraphQL */ `
  query GetFieldsMapping($id: ID!) {
    getFieldsMapping(id: $id) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFieldsMappings = /* GraphQL */ `
  query ListFieldsMappings(
    $filter: ModelFieldsMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFieldsMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentProfile = /* GraphQL */ `
  query GetPaymentProfile($id: ID!) {
    getPaymentProfile(id: $id) {
      id
      tenant
      name
      templateId
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentProfiles = /* GraphQL */ `
  query ListPaymentProfiles(
    $filter: ModelPaymentProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        templateId
        vendorId
        apiKey
        descriptor
        ttl
        siteLogo
        header1
        header2
        sitePrimary
        siteSecondary
        minType
        minRate
        supportEmail
        supportPhone
        promptPaySkills
        channels
        c2a
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentTemplate = /* GraphQL */ `
  query GetPaymentTemplate($id: ID!) {
    getPaymentTemplate(id: $id) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentTemplates = /* GraphQL */ `
  query ListPaymentTemplates(
    $filter: ModelPaymentTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        textBody
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWebhook = /* GraphQL */ `
  query GetWebhook($id: ID!) {
    getWebhook(id: $id) {
      id
      tenant
      event
      url
      auth
      apiToken
      apiTokenHeader
      createdAt
      updatedAt
    }
  }
`;
export const listWebhooks = /* GraphQL */ `
  query ListWebhooks(
    $filter: ModelWebhookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebhooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        event
        url
        auth
        apiToken
        apiTokenHeader
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRestConnection = /* GraphQL */ `
  query GetRestConnection($id: ID!) {
    getRestConnection(id: $id) {
      id
      tenant
      name
      endpoint
      method
      params {
        key
        value
        description
      }
      headers {
        key
        value
        description
      }
      auth {
        type
        info
      }
      enabled
      listPath
      mapping {
        from
        to
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRestConnections = /* GraphQL */ `
  query ListRestConnections(
    $filter: ModelRestConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        endpoint
        method
        params {
          key
          value
          description
        }
        headers {
          key
          value
          description
        }
        auth {
          type
          info
        }
        enabled
        listPath
        mapping {
          from
          to
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMobileConfiguration = /* GraphQL */ `
  query GetMobileConfiguration($id: ID!) {
    getMobileConfiguration(id: $id) {
      id
      tenant
      name
      description
      projectId
      androidEnabled
      iosEnabled
      createdAt
      updatedAt
    }
  }
`;
export const listMobileConfigurations = /* GraphQL */ `
  query ListMobileConfigurations(
    $filter: ModelMobileConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMobileConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        description
        projectId
        androidEnabled
        iosEnabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const segmentByTenant = /* GraphQL */ `
  query SegmentByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    segmentByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const segmentByTenantExternalId = /* GraphQL */ `
  query SegmentByTenantExternalId(
    $tenant: ID
    $externalId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    segmentByTenantExternalId(
      tenant: $tenant
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContactActivity = /* GraphQL */ `
  query GetContactActivity(
    $activityLogContactId: ID
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getContactActivity(
      activityLogContactId: $activityLogContactId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          fieldsMappingId
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          status
          expireDate
          stopOnCompletion
          resetOnCompletion
          static
          campaignSchedule
          segmentSchedule
          updatesEmail
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        c2aId
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          timeZone
          dnc
          updatedBy
          customFields
          expireDt
          complianceRequired
          preview
          outboundCallerId
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTenantActivity = /* GraphQL */ `
  query GetTenantActivity(
    $tenant: ID
    $typeContactStatusTimestamp: ModelActivityLogTenantActivityCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTenantActivity(
      tenant: $tenant
      typeContactStatusTimestamp: $typeContactStatusTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          fieldsMappingId
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          status
          expireDate
          stopOnCompletion
          resetOnCompletion
          static
          campaignSchedule
          segmentSchedule
          updatesEmail
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        c2aId
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          timeZone
          dnc
          updatedBy
          customFields
          expireDt
          complianceRequired
          preview
          outboundCallerId
          type
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const ftpImportsByTenant = /* GraphQL */ `
  query FtpImportsByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFtpImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ftpImportsByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        host
        username
        password
        path
        privateKey
        port
        fieldMappingId
        lastCheck
        fileNameToSource
        algorithms {
          cipher
        }
        readyTimeout
        reportEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignsByTenant = /* GraphQL */ `
  query CampaignsByTenant(
    $tenant: ID
    $skillName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByTenant(
      tenant: $tenant
      skillName: $skillName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignsByStatus = /* GraphQL */ `
  query CampaignsByStatus(
    $status: CampaignStatus
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByStatus(
      status: $status
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profileByTenant = /* GraphQL */ `
  query ProfileByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const configProfielsByTenant = /* GraphQL */ `
  query ConfigProfielsByTenant(
    $tenant: ID
    $mediaType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConfigProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    configProfielsByTenant(
      tenant: $tenant
      mediaType: $mediaType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        direction
        mediaType
        crmEntity
        org {
          name
          id
          url
          version
        }
        connection {
          dynamicsTenant
          appSecret
          clientId
          altUrl
          authUrl
          onPremVersion
        }
        skillIds
        multiSearch
        openClosed
        searchType
        search
        searchAction
        activityEntityMap
        logging
        postLogging
        closeActivity
        loggingAction
        transferSkills
        uiInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const preProcessByTenant = /* GraphQL */ `
  query PreProcessByTenant(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreProcessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PreProcessByTenant(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const agentLogByType = /* GraphQL */ `
  query AgentLogByType(
    $agentId: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCIFLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentLogByType(
      agentId: $agentId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agentId
        tenant
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const uMaxagentLogByType = /* GraphQL */ `
  query UMaxagentLogByType(
    $agentId: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeluMaxLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uMaxagentLogByType(
      agentId: $agentId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agentId
        tenant
        icBu
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantMappings = /* GraphQL */ `
  query TenantMappings(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFieldsMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantMappings(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        mappings {
          fromField
          toField
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantPayProfiles = /* GraphQL */ `
  query TenantPayProfiles(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantPayProfiles(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        templateId
        vendorId
        apiKey
        descriptor
        ttl
        siteLogo
        header1
        header2
        sitePrimary
        siteSecondary
        minType
        minRate
        supportEmail
        supportPhone
        promptPaySkills
        channels
        c2a
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantPayTemplates = /* GraphQL */ `
  query TenantPayTemplates(
    $tenant: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantPayTemplates(
      tenant: $tenant
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        name
        textBody
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tenantHooks = /* GraphQL */ `
  query TenantHooks(
    $tenant: ID
    $event: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebhookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantHooks(
      tenant: $tenant
      event: $event
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        event
        url
        auth
        apiToken
        apiTokenHeader
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchSegments = /* GraphQL */ `
  query SearchSegments(
    $filter: SearchableSegmentFilterInput
    $sort: SearchableSegmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSegments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchActivityLogs = /* GraphQL */ `
  query SearchActivityLogs(
    $filter: SearchableActivityLogFilterInput
    $sort: SearchableActivityLogSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchActivityLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        type
        disposition
        title
        body
        activityLogContactId
        segment {
          id
          tenant
          externalId
          name
          description
          fieldsMappingId
          createdAt
          updatedAt
        }
        campaign {
          id
          tenant
          name
          segments
          sort
          skillId
          skillName
          skillSource
          campaignProfileId
          status
          expireDate
          stopOnCompletion
          resetOnCompletion
          static
          campaignSchedule
          segmentSchedule
          updatesEmail
          createdAt
          updatedAt
        }
        agent
        contactStatus
        timestamp
        metaData
        c2aId
        createdAt
        updatedAt
        contact {
          id
          tenant
          externalId
          firstName
          lastName
          source
          phone
          cell
          email
          dateCreated
          address1
          address2
          city
          state
          zip
          timeZone
          dnc
          updatedBy
          customFields
          expireDt
          complianceRequired
          preview
          outboundCallerId
          type
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const searchTenantSettingss = /* GraphQL */ `
  query SearchTenantSettingss(
    $filter: SearchableTenantSettingsFilterInput
    $sort: SearchableTenantSettingsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTenantSettingss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        icApp
        icVendor
        icBU
        icCluster
        icUserName
        icPassword
        icGetAuthTokenURL
        icAccessKey
        icAppSecret
        icDiscoveryDomain
        icScriptName
        icAPIOverride
        icSMSMapping {
          enabled
        }
        icSMSProviderCredentials {
          provider
          data
          verified
        }
        ftpImport
        ftpExport
        dashBoardCharts
        e2DashCharts
        ppDashCharts
        e2ClientId
        e2ClientSecret
        e2DynamTenantId
        onPremAccessUrl
        onPremVersion
        onPremAuthUrl
        apiKey
        licenses
        customFields {
          name
          displayName
          type
        }
        dynamicsConnection
        dynamicsDispoFilter
        email
        umaxConfig
        permanentCampaigns
        minimumSeats {
          productId
          minimumSeats
        }
        productSku {
          product
          productId
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCampaigns = /* GraphQL */ `
  query SearchCampaigns(
    $filter: SearchableCampaignFilterInput
    $sort: SearchableCampaignSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCampaigns(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchDoNotCalls = /* GraphQL */ `
  query SearchDoNotCalls(
    $filter: SearchableDoNotCallFilterInput
    $sort: SearchableDoNotCallSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoNotCalls(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        phone
        sources
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCampaignLogs = /* GraphQL */ `
  query SearchCampaignLogs(
    $filter: SearchableCampaignLogFilterInput
    $sort: SearchableCampaignLogSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCampaignLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        campaign
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchConfigProfiles = /* GraphQL */ `
  query SearchConfigProfiles(
    $filter: SearchableConfigProfileFilterInput
    $sort: SearchableConfigProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchConfigProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        name
        direction
        mediaType
        crmEntity
        org {
          name
          id
          url
          version
        }
        connection {
          dynamicsTenant
          appSecret
          clientId
          altUrl
          authUrl
          onPremVersion
        }
        skillIds
        multiSearch
        openClosed
        searchType
        search
        searchAction
        activityEntityMap
        logging
        postLogging
        closeActivity
        loggingAction
        transferSkills
        uiInfo
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCifLogs = /* GraphQL */ `
  query SearchCifLogs(
    $filter: SearchableCIFLogFilterInput
    $sort: SearchableCIFLogSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCIFLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        agentId
        tenant
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchUMaxLogs = /* GraphQL */ `
  query SearchUMaxLogs(
    $filter: SearchableuMaxLogFilterInput
    $sort: SearchableuMaxLogSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUMaxLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        agentId
        tenant
        icBu
        type
        metaData
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const tenantExternalId = /* GraphQL */ `
  query TenantExternalId(
    $tenant: ID
    $externalId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantExternalId(
      tenant: $tenant
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchContacts = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: SearchableContactSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getDailyLicenseCount = /* GraphQL */ `
  query GetDailyLicenseCount($id: ID!) {
    getDailyLicenseCount(id: $id) {
      id
      ownerTenant
      tenant
      cdProd
      ncProdNum
      ncBusinessUnit
      Date
      Count
      createdAt
      updatedAt
    }
  }
`;
export const listDailyLicenseCounts = /* GraphQL */ `
  query ListDailyLicenseCounts(
    $filter: ModelDailyLicenseCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyLicenseCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerTenant
        tenant
        cdProd
        ncProdNum
        ncBusinessUnit
        Date
        Count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchDailyLicenseCounts = /* GraphQL */ `
  query SearchDailyLicenseCounts(
    $filter: SearchableDailyLicenseCountFilterInput
    $sort: SearchableDailyLicenseCountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDailyLicenseCounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        ownerTenant
        tenant
        cdProd
        ncProdNum
        ncBusinessUnit
        Date
        Count
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getCryptoPassport = /* GraphQL */ `
  query GetCryptoPassport($id: ID!) {
    getCryptoPassport(id: $id) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listCryptoPassports = /* GraphQL */ `
  query ListCryptoPassports(
    $filter: ModelCryptoPassportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCryptoPassports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
