/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSegment = /* GraphQL */ `
  mutation CreateSegment(
    $input: CreateSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    createSegment(input: $input, condition: $condition) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const updateSegment = /* GraphQL */ `
  mutation UpdateSegment(
    $input: UpdateSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    updateSegment(input: $input, condition: $condition) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const deleteSegment = /* GraphQL */ `
  mutation DeleteSegment(
    $input: DeleteSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    deleteSegment(input: $input, condition: $condition) {
      id
      tenant
      externalId
      name
      description
      query {
        qbQuery
        searchFilter
        sort {
          field
          direction
        }
      }
      fieldsMappingId
      createdAt
      updatedAt
    }
  }
`;
export const createActivityLog = /* GraphQL */ `
  mutation CreateActivityLog(
    $input: CreateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    createActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateActivityLog = /* GraphQL */ `
  mutation UpdateActivityLog(
    $input: UpdateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    updateActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteActivityLog = /* GraphQL */ `
  mutation DeleteActivityLog(
    $input: DeleteActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    deleteActivityLog(input: $input, condition: $condition) {
      id
      tenant
      type
      disposition
      title
      body
      activityLogContactId
      segment {
        id
        tenant
        externalId
        name
        description
        query {
          qbQuery
          searchFilter
        }
        fieldsMappingId
        createdAt
        updatedAt
      }
      campaign {
        id
        tenant
        name
        segments
        sort
        skillId
        skillName
        skillSource
        campaignProfileId
        profile {
          id
          tenant
          name
          useAi
          freshLIFO
          retryLIFO
          createdAt
          updatedAt
        }
        status
        expireDate
        stopOnCompletion
        resetOnCompletion
        static
        campaignSchedule
        segmentSchedule
        updatesEmail
        createdAt
        updatedAt
      }
      agent
      contactStatus
      timestamp
      metaData
      c2aId
      createdAt
      updatedAt
      contact {
        id
        tenant
        externalId
        firstName
        lastName
        source
        phone
        cell
        email
        dateCreated
        address1
        address2
        city
        state
        zip
        timeZone
        dnc
        updatedBy
        customFields
        expireDt
        complianceRequired
        preview
        outboundCallerId
        balances {
          id
          type
          amount
          age
        }
        type
        optout {
          Email
          SMS
          Voice
        }
        mobileDevices {
          type
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTenantSettings = /* GraphQL */ `
  mutation CreateTenantSettings(
    $input: CreateTenantSettingsInput!
    $condition: ModelTenantSettingsConditionInput
  ) {
    createTenantSettings(input: $input, condition: $condition) {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTenantSettings = /* GraphQL */ `
  mutation UpdateTenantSettings(
    $input: UpdateTenantSettingsInput!
    $condition: ModelTenantSettingsConditionInput
  ) {
    updateTenantSettings(input: $input, condition: $condition) {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenantSettings = /* GraphQL */ `
  mutation DeleteTenantSettings(
    $input: DeleteTenantSettingsInput!
    $condition: ModelTenantSettingsConditionInput
  ) {
    deleteTenantSettings(input: $input, condition: $condition) {
      id
      icApp
      icVendor
      icBU
      icCluster
      icUserName
      icPassword
      icGetAuthTokenURL
      icAccessKey
      icAppSecret
      icDiscoveryDomain
      icScriptName
      icAPIOverride
      icSMSMapping {
        enabled
        mappings {
          phone
          nicPocName
          nicPocGuid
          provider
          verified
          appKey
        }
      }
      icSMSProviderCredentials {
        provider
        data
        verified
      }
      ftpImport
      ftpExport
      dashBoardCharts
      e2DashCharts
      ppDashCharts
      e2ClientId
      e2ClientSecret
      e2DynamTenantId
      onPremAccessUrl
      onPremVersion
      onPremAuthUrl
      apiKey
      licenses
      customFields {
        name
        displayName
        type
      }
      dynamicsConnection
      dynamicsDispoFilter
      email
      umaxConfig
      permanentCampaigns
      minimumSeats {
        productId
        minimumSeats
      }
      productSku {
        product
        productId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFtpImport = /* GraphQL */ `
  mutation CreateFtpImport(
    $input: CreateFtpImportInput!
    $condition: ModelFtpImportConditionInput
  ) {
    createFtpImport(input: $input, condition: $condition) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateFtpImport = /* GraphQL */ `
  mutation UpdateFtpImport(
    $input: UpdateFtpImportInput!
    $condition: ModelFtpImportConditionInput
  ) {
    updateFtpImport(input: $input, condition: $condition) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteFtpImport = /* GraphQL */ `
  mutation DeleteFtpImport(
    $input: DeleteFtpImportInput!
    $condition: ModelFtpImportConditionInput
  ) {
    deleteFtpImport(input: $input, condition: $condition) {
      id
      tenant
      name
      host
      username
      password
      path
      privateKey
      port
      fieldMappingId
      lastCheck
      fileNameToSource
      algorithms {
        cipher
      }
      readyTimeout
      reportEmail
      createdAt
      updatedAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      resetOnCompletion
      static
      campaignSchedule
      segmentSchedule
      updatesEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      resetOnCompletion
      static
      campaignSchedule
      segmentSchedule
      updatesEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      tenant
      name
      segments
      sort
      skillId
      skillName
      skillSource
      campaignProfileId
      profile {
        id
        tenant
        name
        useAi
        scrubber {
          cell
          natDnc
          stateDnc
          orgDnc
          phoneDup
          contactDup
        }
        retry {
          minDuration
          maxAttempts
          maxContacts
          custRetry
          ratio
        }
        channels {
          type
          order
          retryDelay
          maxAttempts
          maxContacts
          custRetry
          ratio
          paymentProfileId
          paymentProfileName
          defaultTimeZone
        }
        ai {
          org
          system
        }
        freshLIFO
        retryLIFO
        createdAt
        updatedAt
      }
      status
      expireDate
      stopOnCompletion
      resetOnCompletion
      static
      campaignSchedule
      segmentSchedule
      updatesEmail
      createdAt
      updatedAt
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      useAi
      scrubber {
        cell
        natDnc
        stateDnc
        orgDnc
        phoneDup
        contactDup
      }
      retry {
        minDuration
        maxAttempts
        maxContacts
        custRetry
        ratio
      }
      channels {
        type
        order
        retryDelay
        maxAttempts
        maxContacts
        custRetry
        ratio
        paymentProfileId
        paymentProfileName
        defaultTimeZone
      }
      ai {
        org
        system
      }
      freshLIFO
      retryLIFO
      createdAt
      updatedAt
    }
  }
`;
export const createDoNotCall = /* GraphQL */ `
  mutation CreateDoNotCall(
    $input: CreateDoNotCallInput!
    $condition: ModelDoNotCallConditionInput
  ) {
    createDoNotCall(input: $input, condition: $condition) {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const updateDoNotCall = /* GraphQL */ `
  mutation UpdateDoNotCall(
    $input: UpdateDoNotCallInput!
    $condition: ModelDoNotCallConditionInput
  ) {
    updateDoNotCall(input: $input, condition: $condition) {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoNotCall = /* GraphQL */ `
  mutation DeleteDoNotCall(
    $input: DeleteDoNotCallInput!
    $condition: ModelDoNotCallConditionInput
  ) {
    deleteDoNotCall(input: $input, condition: $condition) {
      id
      tenant
      phone
      sources
      createdAt
      updatedAt
    }
  }
`;
export const createCampaignLog = /* GraphQL */ `
  mutation CreateCampaignLog(
    $input: CreateCampaignLogInput!
    $condition: ModelCampaignLogConditionInput
  ) {
    createCampaignLog(input: $input, condition: $condition) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaignLog = /* GraphQL */ `
  mutation UpdateCampaignLog(
    $input: UpdateCampaignLogInput!
    $condition: ModelCampaignLogConditionInput
  ) {
    updateCampaignLog(input: $input, condition: $condition) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaignLog = /* GraphQL */ `
  mutation DeleteCampaignLog(
    $input: DeleteCampaignLogInput!
    $condition: ModelCampaignLogConditionInput
  ) {
    deleteCampaignLog(input: $input, condition: $condition) {
      id
      tenant
      campaign
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const createConfigProfile = /* GraphQL */ `
  mutation CreateConfigProfile(
    $input: CreateConfigProfileInput!
    $condition: ModelConfigProfileConditionInput
  ) {
    createConfigProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const updateConfigProfile = /* GraphQL */ `
  mutation UpdateConfigProfile(
    $input: UpdateConfigProfileInput!
    $condition: ModelConfigProfileConditionInput
  ) {
    updateConfigProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfigProfile = /* GraphQL */ `
  mutation DeleteConfigProfile(
    $input: DeleteConfigProfileInput!
    $condition: ModelConfigProfileConditionInput
  ) {
    deleteConfigProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      direction
      mediaType
      crmEntity
      org {
        name
        id
        url
        version
      }
      connection {
        dynamicsTenant
        appSecret
        clientId
        altUrl
        authUrl
        onPremVersion
      }
      skillIds
      multiSearch
      openClosed
      searchType
      search
      searchAction
      activityEntityMap
      logging
      postLogging
      closeActivity
      loggingAction
      transferSkills
      uiInfo
      createdAt
      updatedAt
    }
  }
`;
export const createPreProcess = /* GraphQL */ `
  mutation CreatePreProcess(
    $input: CreatePreProcessInput!
    $condition: ModelPreProcessConditionInput
  ) {
    createPreProcess(input: $input, condition: $condition) {
      id
      tenant
      name
      action
      createdAt
      updatedAt
    }
  }
`;
export const updatePreProcess = /* GraphQL */ `
  mutation UpdatePreProcess(
    $input: UpdatePreProcessInput!
    $condition: ModelPreProcessConditionInput
  ) {
    updatePreProcess(input: $input, condition: $condition) {
      id
      tenant
      name
      action
      createdAt
      updatedAt
    }
  }
`;
export const deletePreProcess = /* GraphQL */ `
  mutation DeletePreProcess(
    $input: DeletePreProcessInput!
    $condition: ModelPreProcessConditionInput
  ) {
    deletePreProcess(input: $input, condition: $condition) {
      id
      tenant
      name
      action
      createdAt
      updatedAt
    }
  }
`;
export const createCifLog = /* GraphQL */ `
  mutation CreateCifLog(
    $input: CreateCIFLogInput!
    $condition: ModelCIFLogConditionInput
  ) {
    createCIFLog(input: $input, condition: $condition) {
      id
      agentId
      tenant
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const updateCifLog = /* GraphQL */ `
  mutation UpdateCifLog(
    $input: UpdateCIFLogInput!
    $condition: ModelCIFLogConditionInput
  ) {
    updateCIFLog(input: $input, condition: $condition) {
      id
      agentId
      tenant
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const deleteCifLog = /* GraphQL */ `
  mutation DeleteCifLog(
    $input: DeleteCIFLogInput!
    $condition: ModelCIFLogConditionInput
  ) {
    deleteCIFLog(input: $input, condition: $condition) {
      id
      agentId
      tenant
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const createUMaxLog = /* GraphQL */ `
  mutation CreateUMaxLog(
    $input: CreateUMaxLogInput!
    $condition: ModeluMaxLogConditionInput
  ) {
    createUMaxLog(input: $input, condition: $condition) {
      id
      agentId
      tenant
      icBu
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const updateUMaxLog = /* GraphQL */ `
  mutation UpdateUMaxLog(
    $input: UpdateUMaxLogInput!
    $condition: ModeluMaxLogConditionInput
  ) {
    updateUMaxLog(input: $input, condition: $condition) {
      id
      agentId
      tenant
      icBu
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const deleteUMaxLog = /* GraphQL */ `
  mutation DeleteUMaxLog(
    $input: DeleteUMaxLogInput!
    $condition: ModeluMaxLogConditionInput
  ) {
    deleteUMaxLog(input: $input, condition: $condition) {
      id
      agentId
      tenant
      icBu
      type
      metaData
      createdAt
      updatedAt
    }
  }
`;
export const createFieldsMapping = /* GraphQL */ `
  mutation CreateFieldsMapping(
    $input: CreateFieldsMappingInput!
    $condition: ModelFieldsMappingConditionInput
  ) {
    createFieldsMapping(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFieldsMapping = /* GraphQL */ `
  mutation UpdateFieldsMapping(
    $input: UpdateFieldsMappingInput!
    $condition: ModelFieldsMappingConditionInput
  ) {
    updateFieldsMapping(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFieldsMapping = /* GraphQL */ `
  mutation DeleteFieldsMapping(
    $input: DeleteFieldsMappingInput!
    $condition: ModelFieldsMappingConditionInput
  ) {
    deleteFieldsMapping(input: $input, condition: $condition) {
      id
      tenant
      name
      mappings {
        fromField
        toField
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentProfile = /* GraphQL */ `
  mutation CreatePaymentProfile(
    $input: CreatePaymentProfileInput!
    $condition: ModelPaymentProfileConditionInput
  ) {
    createPaymentProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      templateId
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentProfile = /* GraphQL */ `
  mutation UpdatePaymentProfile(
    $input: UpdatePaymentProfileInput!
    $condition: ModelPaymentProfileConditionInput
  ) {
    updatePaymentProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      templateId
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentProfile = /* GraphQL */ `
  mutation DeletePaymentProfile(
    $input: DeletePaymentProfileInput!
    $condition: ModelPaymentProfileConditionInput
  ) {
    deletePaymentProfile(input: $input, condition: $condition) {
      id
      tenant
      name
      templateId
      vendorId
      apiKey
      descriptor
      ttl
      siteLogo
      header1
      header2
      sitePrimary
      siteSecondary
      minType
      minRate
      supportEmail
      supportPhone
      promptPaySkills
      channels
      c2a
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentTemplate = /* GraphQL */ `
  mutation CreatePaymentTemplate(
    $input: CreatePaymentTemplateInput!
    $condition: ModelPaymentTemplateConditionInput
  ) {
    createPaymentTemplate(input: $input, condition: $condition) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentTemplate = /* GraphQL */ `
  mutation UpdatePaymentTemplate(
    $input: UpdatePaymentTemplateInput!
    $condition: ModelPaymentTemplateConditionInput
  ) {
    updatePaymentTemplate(input: $input, condition: $condition) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentTemplate = /* GraphQL */ `
  mutation DeletePaymentTemplate(
    $input: DeletePaymentTemplateInput!
    $condition: ModelPaymentTemplateConditionInput
  ) {
    deletePaymentTemplate(input: $input, condition: $condition) {
      id
      tenant
      name
      textBody
      type
      createdAt
      updatedAt
    }
  }
`;
export const createWebhook = /* GraphQL */ `
  mutation CreateWebhook(
    $input: CreateWebhookInput!
    $condition: ModelWebhookConditionInput
  ) {
    createWebhook(input: $input, condition: $condition) {
      id
      tenant
      event
      url
      auth
      apiToken
      apiTokenHeader
      createdAt
      updatedAt
    }
  }
`;
export const updateWebhook = /* GraphQL */ `
  mutation UpdateWebhook(
    $input: UpdateWebhookInput!
    $condition: ModelWebhookConditionInput
  ) {
    updateWebhook(input: $input, condition: $condition) {
      id
      tenant
      event
      url
      auth
      apiToken
      apiTokenHeader
      createdAt
      updatedAt
    }
  }
`;
export const deleteWebhook = /* GraphQL */ `
  mutation DeleteWebhook(
    $input: DeleteWebhookInput!
    $condition: ModelWebhookConditionInput
  ) {
    deleteWebhook(input: $input, condition: $condition) {
      id
      tenant
      event
      url
      auth
      apiToken
      apiTokenHeader
      createdAt
      updatedAt
    }
  }
`;
export const createRestConnection = /* GraphQL */ `
  mutation CreateRestConnection(
    $input: CreateRestConnectionInput!
    $condition: ModelRestConnectionConditionInput
  ) {
    createRestConnection(input: $input, condition: $condition) {
      id
      tenant
      name
      endpoint
      method
      params {
        key
        value
        description
      }
      headers {
        key
        value
        description
      }
      auth {
        type
        info
      }
      enabled
      listPath
      mapping {
        from
        to
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRestConnection = /* GraphQL */ `
  mutation UpdateRestConnection(
    $input: UpdateRestConnectionInput!
    $condition: ModelRestConnectionConditionInput
  ) {
    updateRestConnection(input: $input, condition: $condition) {
      id
      tenant
      name
      endpoint
      method
      params {
        key
        value
        description
      }
      headers {
        key
        value
        description
      }
      auth {
        type
        info
      }
      enabled
      listPath
      mapping {
        from
        to
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRestConnection = /* GraphQL */ `
  mutation DeleteRestConnection(
    $input: DeleteRestConnectionInput!
    $condition: ModelRestConnectionConditionInput
  ) {
    deleteRestConnection(input: $input, condition: $condition) {
      id
      tenant
      name
      endpoint
      method
      params {
        key
        value
        description
      }
      headers {
        key
        value
        description
      }
      auth {
        type
        info
      }
      enabled
      listPath
      mapping {
        from
        to
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMobileConfiguration = /* GraphQL */ `
  mutation CreateMobileConfiguration(
    $input: CreateMobileConfigurationInput!
    $condition: ModelMobileConfigurationConditionInput
  ) {
    createMobileConfiguration(input: $input, condition: $condition) {
      id
      tenant
      name
      description
      projectId
      androidEnabled
      iosEnabled
      createdAt
      updatedAt
    }
  }
`;
export const updateMobileConfiguration = /* GraphQL */ `
  mutation UpdateMobileConfiguration(
    $input: UpdateMobileConfigurationInput!
    $condition: ModelMobileConfigurationConditionInput
  ) {
    updateMobileConfiguration(input: $input, condition: $condition) {
      id
      tenant
      name
      description
      projectId
      androidEnabled
      iosEnabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteMobileConfiguration = /* GraphQL */ `
  mutation DeleteMobileConfiguration(
    $input: DeleteMobileConfigurationInput!
    $condition: ModelMobileConfigurationConditionInput
  ) {
    deleteMobileConfiguration(input: $input, condition: $condition) {
      id
      tenant
      name
      description
      projectId
      androidEnabled
      iosEnabled
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      tenant
      externalId
      firstName
      lastName
      source
      phone
      cell
      email
      dateCreated
      address1
      address2
      city
      state
      zip
      timeZone
      dnc
      updatedBy
      customFields
      expireDt
      complianceRequired
      preview
      outboundCallerId
      balances {
        id
        type
        amount
        age
      }
      type
      optout {
        Email
        SMS
        Voice
      }
      mobileDevices {
        type
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDailyLicenseCount = /* GraphQL */ `
  mutation CreateDailyLicenseCount(
    $input: CreateDailyLicenseCountInput!
    $condition: ModelDailyLicenseCountConditionInput
  ) {
    createDailyLicenseCount(input: $input, condition: $condition) {
      id
      ownerTenant
      tenant
      cdProd
      ncProdNum
      ncBusinessUnit
      Date
      Count
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyLicenseCount = /* GraphQL */ `
  mutation UpdateDailyLicenseCount(
    $input: UpdateDailyLicenseCountInput!
    $condition: ModelDailyLicenseCountConditionInput
  ) {
    updateDailyLicenseCount(input: $input, condition: $condition) {
      id
      ownerTenant
      tenant
      cdProd
      ncProdNum
      ncBusinessUnit
      Date
      Count
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyLicenseCount = /* GraphQL */ `
  mutation DeleteDailyLicenseCount(
    $input: DeleteDailyLicenseCountInput!
    $condition: ModelDailyLicenseCountConditionInput
  ) {
    deleteDailyLicenseCount(input: $input, condition: $condition) {
      id
      ownerTenant
      tenant
      cdProd
      ncProdNum
      ncBusinessUnit
      Date
      Count
      createdAt
      updatedAt
    }
  }
`;
export const createCryptoPassport = /* GraphQL */ `
  mutation CreateCryptoPassport(
    $input: CreateCryptoPassportInput!
    $condition: ModelCryptoPassportConditionInput
  ) {
    createCryptoPassport(input: $input, condition: $condition) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateCryptoPassport = /* GraphQL */ `
  mutation UpdateCryptoPassport(
    $input: UpdateCryptoPassportInput!
    $condition: ModelCryptoPassportConditionInput
  ) {
    updateCryptoPassport(input: $input, condition: $condition) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteCryptoPassport = /* GraphQL */ `
  mutation DeleteCryptoPassport(
    $input: DeleteCryptoPassportInput!
    $condition: ModelCryptoPassportConditionInput
  ) {
    deleteCryptoPassport(input: $input, condition: $condition) {
      id
      token
      ttl
      createdAt
      updatedAt
    }
  }
`;
